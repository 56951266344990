<template>
  <div class="position-relative z4">
    <div class="dialog-title mb-8">
      HASIL PENILAIAN HALAMAN
    </div>

    <v-card class="p-4 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          {{ data.url }}
        </div>
        <div>Tes dilakukan pada {{ formatDate(data.created) }}</div>
      </div>
    </v-card>

    <b-row>
      <b-col cols="12" md="4">
        <v-card>
          <div class="card card-custom gutter-b">
            <div
              class="card-header justify-content-center align-items-center h4"
            >
              <i class="flaticon2-line-chart text-primary mr-2"></i>
              Performa
            </div>
            <div
              class="card-body text-primary text-center"
              style="font-size: 26px"
            >
              {{ Math.round((result.performa * 100) / 55) }}
            </div>
          </div>
        </v-card>
      </b-col>
      <b-col cols="12" md="4">
        <v-card>
          <div class="card card-custom gutter-b">
            <div
              class="card-header justify-content-center align-items-center h4"
            >
              <i class="flaticon-pie-chart-1 text-primary mr-2"></i>
              Data Quality
            </div>
            <div
              class="card-body text-primary text-center"
              style="font-size: 26px"
            >
              {{ Math.round((result.dataQuality * 100) / 15) }}
            </div>
          </div>
        </v-card>
      </b-col>
      <b-col cols="12" md="4">
        <v-card>
          <div class="card card-custom gutter-b">
            <div
              class="card-header justify-content-center align-items-center h4"
            >
              <i class="flaticon2-chart text-primary mr-2"></i>
              Guideline
            </div>
            <div
              class="card-body text-primary text-center"
              style="font-size: 26px"
            >
              {{ Math.round((result.guidelines * 100) / 30) }}
            </div>
          </div>
        </v-card>
      </b-col>
    </b-row>

    <v-card>
      <div class="card card-custom gutter-b">
        <div class="card-header justify-content-center align-items-center h4">
          <i class="flaticon2-medical-records-1 text-primary mr-2"></i>
          Total Akhir
        </div>
        <div class="card-body text-primary text-center" style="font-size: 26px">
          {{ result.guidelines + result.dataQuality + result.performa }} / 100
          <div class="progress mt-6 mb-6">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{
                width:
                  result.guidelines + result.dataQuality + result.performa + '%'
              }"
              :aria-valuenow="
                result.guidelines + result.dataQuality + result.performa
              "
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <apexchart
            v-if="chartv"
            type="radar"
            height="500"
            :options="chartv.options"
            :series="chartv.series"
          ></apexchart>
        </div>
      </div>
    </v-card>

    <v-card>
      <!-- <div>
        <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <p class="card-text">Collapse contents Here</p>
          </b-card>
        </b-collapse>
      </div> -->

      <table class="table c-table-hover">
        <thead>
          <tr>
            <th width="20%">Parameter Penilaian</th>
            <th width="15%">Kategori</th>
            <th width="10%">Prioritas</th>
            <th width="55%">Kesimpulan</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <template v-for="(item, i) in result.table">
            <tr
              :key="'row_' + i"
              v-b-toggle="'collapse-' + i"
              class="cell-1 accordion accordion-toggle-arrow"
              data-toggle="collapse"
            >
              <td>
                <i
                  v-if="item.recommendation.length > 0"
                  :key="'icon_' + i"
                  class="flaticon2-fast-next text-primary mr-2"
                ></i>
                {{ item.nama }}
              </td>
              <td>{{ item.type }}</td>
              <td>
                <span
                  :class="
                    `badge ${
                      item.status === 'High'
                        ? 'badge-danger'
                        : item.status === 'Medium'
                        ? 'badge-warning'
                        : 'badge-success'
                    }`
                  "
                >
                  {{ item.status }}
                </span>
              </td>
              <td>{{ item.ket }}</td>
            </tr>
            <tr :key="'row2_' + i">
              <td class="p-0"></td>
              <td class="p-0 bg-gray" colspan="3">
                <b-collapse
                  v-if="item.recommendation.length !== 0"
                  :id="'collapse-' + i"
                >
                  <div class="px-3 py-4">
                    <div class="mb-2">
                      <i class="flaticon2-rocket text-primary mr-2"></i>
                      Rekomendasi
                    </div>

                    <div
                      v-for="(rec, recIdx) in item.recommendation"
                      :key="'row_' + i + '_' + recIdx"
                    >
                      <v-divider></v-divider>
                      {{ rec }}
                    </div>
                  </div>
                </b-collapse>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
* {
  font-family: "Aller";
  font-size: 14px;
}

.program-title {
  margin-bottom: 10px;
  line-height: 22px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: #8c8c8c;
}
.program-pic {
  font-style: normal;
  color: #a29f9d;
}
.program-duration {
  display: inline-block;
  padding: 4px 12px;
  color: #a29f9d;
  border: 1px solid #d7d4d3;
  border-radius: 40px;
}
.program-desc {
  margin: 20px 0;
}
.program-tag {
  margin-right: 10px;
  padding: 4px 16px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 40px;
  color: #1e9354;
  background-color: rgba(6, 181, 111, 0.22);
}
.program-unit {
  text-align: justify;
}
.program-komponen {
  margin-bottom: 10px;
  padding-left: 12px;
  border-left: 6px solid;

  .program-komponen-title {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .program-komponen-desc {
    margin-bottom: 4px;
    font-size: 13px;
    color: #a6a5a5;
  }
}

.c-table-hover tbody tr:hover td,
.c-table-hover tbody tr:hover th {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";

import ApiService from "@/core/services/api.service";

const tofixed = val => {
  return parseFloat(val.toFixed(2));
};

export default {
  props: ["data", "show", "closeDialog"],
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      elearningUrl: ApiService.elearningUrl,
      routesName: ["Program"],
      activeTabIndex: 0,

      result: {},
      chartv: null,

      dialog: false,
      confirmationDialog: false
    };
  },
  mounted() {},
  beforeMount() {
    this.settingData();
  },
  beforeDestroy() {},
  methods: {
    settingData() {
      let datas = JSON.parse(this.data.recommendation);
      datas.result = datas.calculate;

      let dataQuality = datas?.manual?.dataQuality || [];
      let completeness = datas?.manual?.dataQuality[0] == "Ya" ? 2 : 0;
      let uniqueness = datas?.manual?.dataQuality[1] == "Ya" ? 2 : 0;
      let countValidity = 0;

      let listrecDataQ = [
        "Memperbaiki type data yang digunakan dan menentukan range nilai pada variabel tersebut",
        "Membuat validasi pada form input",
        "Membuat rule penulisan kalendar",
        "Memperbaiki sistem kalendar",
        "Membuat rule penulisan waktu",
        "Membuat fungsi untuk menampilkan comma untuk nilai yang besar pada sistem, ex menggunakan toLocalString pada variable Javascript ",
        "Membuat rule penulisan pada angka yang besar",
        "Membuat validasi pada form input, memberikan petunjuk penulisan pada form input",
        "Membuat error message pada form",
        "Membuat error message pada form; menentukan batasan pada form input",
        "Membuat tanda mandatory fill",
        "Membuat rule penulisan",
        "Membuat rule penulisan filename",
        "Melakukan pengisian ulang data",
        "Menghapus data yang sama",
        "Melakukan pembaruan secara berkala"
      ];
      let arrDataQuality = [];
      if (datas?.manual?.dataQuality[0] !== "Ya")
        arrDataQuality.push(listrecDataQ[13]);
      if (datas?.manual?.dataQuality[1] !== "Ya")
        arrDataQuality.push(listrecDataQ[14]);

      for (let i = 2; i < dataQuality.length - 2; i++) {
        countValidity =
          dataQuality[i] == "Ya" ? countValidity + 1 : countValidity;
        if (dataQuality[i] !== "Ya") {
          arrDataQuality.push(listrecDataQ[i - 2]);
        }
      }
      let validity =
        countValidity < 3
          ? 1.2
          : countValidity > 3 && countValidity <= 9
          ? 2.4
          : 4;
      let timelines = dataQuality[15] == "Ya" ? 2 : 0;
      let acessControl = dataQuality[16] == "Ya" ? 5 : 0;

      if (dataQuality[15] !== "Ya") arrDataQuality.push(listrecDataQ[15]);

      let simply = datas?.manual?.simplicity.filter(() => "Ya").length;
      let simplicity = simply <= 6 ? 3 : simply <= 13 && simply > 7 ? 6 : 10;

      let listrecSimplicity = [
        "Perbaiki posisi tampilan",
        "Membuat error message ketika exception condition muncul",
        "Lakukan pengentasan dari setiap jenis screen dan perbaiki",
        "Mengatur ukuran font minimal 12 px pada setidaknya 60% teks pada halaman",
        "Membuat website menjadi responsive",
        "Mengatur jarak icon agar mudah diklik meskipun menggunakan mobile device, membuat ukuran icon dan font mirip, mengatur white space pada halaman ",
        "Membuat contras ratio antara font dan backround (follow: https://material.io/design/usability/accessibility.html#color-contrast)",
        "Membuat contras ratio antara font dan backround (follow: https://material.io/design/usability/accessibility.html#color-contrast)",
        "Meningkatkan kualitas gambar, menggunakan format image next-gen seperti JPEG 2000, JPEG XR, and WebP; https://web.dev/uses-webp-images/)",
        "https://material.io/design/layout/responsive-layout-grid.html#grid-customization",
        "Menambahkan spell checker plugin pada website",
        "Perbaiki tampilan sesuai dengan kaidah user experience",
        "Mengatur ukuran scrollbar secara otomatis sesuai dengan device yang digunakan oleh user",
        "Lakukan pengetesan untuk field yang di disabled",
        "menggunakan format image next-gen seperti JPEG 2000, JPEG XR, and WebP; https://web.dev/uses-webp-images/), tidak menggunakan image dengan resolusi yang lebih besar daripada yang ditampilkan dilayar",
        "Mengatur ukuran headings sesuai dengan target user",
        "Membuat contras ratio antara font dan backround (follow: https://material.io/design/usability/accessibility.html#color-contrast)"
      ];
      let arrSimplicity = [];
      for (let sim = 0; sim < datas?.manual?.simplicity.length; sim++) {
        if (datas?.manual?.simplicity[sim] !== "Ya")
          arrSimplicity.push(listrecSimplicity[sim]);
      }

      datas.performa =
        datas?.result?.brokenlink_checker?.result_percent +
        datas?.result?.website_performance?.number_request_percent +
        datas?.result?.website_performance?.page_load_percent +
        datas?.result?.website_performance?.page_size_percent +
        datas?.result?.website_performance?.respon_time_percent +
        datas?.result?.ssl_certificate?.result_percent;

      datas.dataQuality =
        completeness + uniqueness + validity + timelines + acessControl;

      datas.guidelines =
        datas?.result?.additional_guidelines.result_percent +
        datas?.result?.responsive.result_percent +
        simplicity;

      this.chartv = {
        series: [
          {
            name: "Performa",
            data: [
              (tofixed(datas?.result?.brokenlink_checker.result_percent) *
                100) /
                15,
              tofixed(
                ((datas?.result?.website_performance.number_request_percent +
                  datas?.result?.website_performance.page_load_percent +
                  datas?.result?.website_performance.page_size_percent +
                  datas?.result?.website_performance.respon_time_percent) *
                  100) /
                  20
              ),

              tofixed(
                (datas?.result?.ssl_certificate.result_percent * 100) / 20
              ),
              0,
              0,
              0,
              0,
              0
            ]
          },
          {
            name: "Data Quality",
            data: [
              0,
              0,
              0,
              tofixed(
                ((completeness + uniqueness + validity + timelines) * 100) / 10
              ),
              tofixed((acessControl * 100) / 5),
              0,
              0,
              0
            ]
          },
          {
            name: "Guidelines",
            data: [
              0,
              0,
              0,
              0,
              0,
              tofixed(
                (datas?.result?.additional_guidelines.result_percent * 100) / 10
              ),
              tofixed((datas?.result?.responsive.result_percent * 100) / 10),
              tofixed((simplicity * 100) / 10)
            ]
          }
        ],
        options: {
          chart: {
            height: 250,
            type: "radar"
          },

          xaxis: {
            categories: [
              "Broken Link Check",
              "Website Performance",
              "SSL",
              "Data Quality",
              "Access Control",
              "Additional Guides",
              "Responsive",
              "Simplicity"
            ]
          }
        }
      };

      let detailResult = [];
      let brokenresult = {};
      let brokenRec = datas?.recommendation.brokenlink_checker;

      if (datas?.detail?.brokenlink_checker.count_url_error < 20) {
        brokenresult = {
          type: "Performa",
          nama: "Broken link Checker",
          status: "Low",
          ket:
            "Sistem Anda sudah baik. Untuk memastikan kualitas sistem diharapkan untuk mengecek sistem secara berkala.",
          recommendation: brokenRec
        };
      } else if (
        datas?.detail?.brokenlink_checker.count_url_error >= 20 &&
        datas?.detail?.brokenlink_checker.count_url_error < 70
      ) {
        brokenresult = {
          type: "Performa",
          nama: "Broken link Checker",
          status: "Medium",
          ket:
            "Sistem Anda sudah cukup baik, namun masih terdapat beberapa link yang rusak.",
          recommendation: brokenRec
        };
      } else {
        brokenresult = {
          type: "Performa",
          nama: "Broken link Checker",
          status: "High",
          ket: "Sistem Anda memiliki banyak link yang rusak.",
          recommendation: brokenRec
        };
      }
      detailResult.push(brokenresult);

      let poorCount = 0;
      let fairCount = 0;

      let lt = datas?.result?.website_performance.page_load / 1000;
      let ltstat = lt >= 12 ? "poor" : lt >= 6 && lt < 12 ? "fair" : "good";
      fairCount = ltstat == "fair" ? fairCount + 1 : fairCount;
      poorCount = ltstat == "poor" ? poorCount + 1 : poorCount;

      let ps = datas?.result?.website_performance.page_size;
      let psstat =
        ps >= 2500 ? "poor" : lt >= 1800 && lt < 2500 ? "fair" : "good";
      fairCount = psstat == "fair" ? fairCount + 1 : fairCount;
      poorCount = psstat == "poor" ? poorCount + 1 : poorCount;

      let nr = datas?.result?.website_performance.number_request;
      let nrstat = nr >= 100 ? "poor" : lt >= 80 && lt < 100 ? "fair" : "good";
      fairCount = nrstat == "fair" ? fairCount + 1 : fairCount;
      poorCount = nrstat == "poor" ? poorCount + 1 : poorCount;

      let rt = datas?.result?.website_performance.respon_time;
      let rtstat =
        rt > 2000 ? "poor" : lt >= 1500 && lt <= 2000 ? "fair" : "good";
      fairCount = rtstat == "fair" ? fairCount + 1 : fairCount;
      poorCount = rtstat == "poor" ? poorCount + 1 : poorCount;

      let perfoRec = [];
      if (datas?.recommendation.website_performance.number_request) {
        perfoRec.push(datas?.recommendation.website_performance.number_request);
      }
      if (datas?.recommendation.website_performance.page_load) {
        perfoRec.push(datas?.recommendation.website_performance.page_load);
      }
      if (datas?.recommendation.website_performance.page_size) {
        perfoRec.push(datas?.recommendation.website_performance.page_size);
      }
      if (datas?.recommendation.website_performance.respon_time) {
        perfoRec.push(datas?.recommendation.website_performance.respon_time);
      }

      if (fairCount == 0 && poorCount == 0) {
        detailResult.push({
          type: "Performa",
          nama: "Website Perfomance",
          status: "Low",
          ket: "Sistem Anda telah memiliki performa yang baik.",
          recommendation: perfoRec
        });
      } else if (fairCount == 1 && poorCount == 0) {
        detailResult.push({
          type: "Performa",
          nama: "Website Perfomance",
          status: "Low",
          ket: "Sistem Anda telah memiliki performa yang baik.",
          recommendation: perfoRec
        });
      } else if (fairCount == 2 && poorCount == 0) {
        detailResult.push({
          type: "Performa",
          nama: "Website Perfomance",
          status: "Medium",
          ket: "Sistem Anda cukup memiliki performa yang baik.",
          recommendation: perfoRec
        });
      } else {
        detailResult.push({
          type: "Performa",
          nama: "Website Perfomance",
          status: "High",
          ket: "Sistem Anda perlu perbaikan dan peningkatan performa.",
          recommendation: perfoRec
        });
      }

      let sslRec = [];
      if (datas?.recommendation.ssl_certificate.responsive)
        sslRec.push(datas?.recommendation.ssl_certificate.responsive);
      if (datas?.detail?.ssl_certificate.res_ssl) {
        detailResult.push({
          type: "Performa",
          nama: "SSL Certificate availability",
          status: "Low",
          ket: "Keamanan sistem telah dilengkapi dengan SSL certificate.",
          recommendation: sslRec
        });
      } else {
        detailResult.push({
          type: "Performa",
          nama: "SSL Certificate availability",
          status: "High",
          ket:
            "Sistem memerlukan sistem keamanan. Harap menambahkan SSL certificate pada sistem Anda.",
          recommendation: sslRec
        });
      }
      let resRec = [];
      if (datas?.recommendation.responsive.responsive)
        resRec.push(datas?.recommendation.responsive.responsive);

      if (datas?.detail?.responsive.res_responsive) {
        detailResult.push({
          type: "Guidelines",
          nama: "Responsive Web Design",
          status: "Low",
          ket: "Sistem dapat diakses dengan baik diberbagai jenis perangkat.",
          recommendation: resRec
        });
      } else {
        detailResult.push({
          type: "Guidelines",
          nama: "Responsive Web Design",
          status: "High",
          ket:
            "Sistem perlu diperbaiki agar dapat digunakan dengan optimal diberbagai perangkat.",
          recommendation: resRec
        });
      }

      //manual
      // let access_control = 0
      let accC = [];
      if (acessControl == 0) {
        accC.push("Menambahkan akses kontrol sesuai dengan kewenangan user");
      }
      if (acessControl == 5) {
        detailResult.push({
          type: "Data Quality",
          nama: "Access Control",
          status: "Low",
          ket: "Sudah terdapat user level system pada sistem Anda.",
          recommendation: accC
        });
      } else {
        detailResult.push({
          type: "Data Quality",
          nama: "Access Control",
          status: "High",
          ket: "Perlu dikembangkan user level system pada sistem Anda. ",
          recommendation: accC
        });
      }

      //data quality
      // let dqualityrec = []

      let dquality = datas?.dataQuality;
      if (dquality <= 4) {
        detailResult.push({
          type: "Data Quality",
          nama: "Data Quality",
          status: "High",
          ket: "Kualitas data dalam sistem Anda perlu dicek dan diperbaiki.",
          recommendation: arrDataQuality
        });
      } else if (dquality > 4 && dquality <= 7) {
        detailResult.push({
          type: "Data Quality",
          nama: "Data Quality",
          status: "Medium",
          ket:
            "Kualitas data dalam sistem Anda cukup baik, namun terdapat beberapa poin yang perlu dicek dan diperbaiki.",
          recommendation: arrDataQuality
        });
      } else {
        detailResult.push({
          type: "Data Quality",
          nama: "Data Quality",
          status: "Low",
          ket: "Kualitas data dalam sistem Anda baik.",
          recommendation: arrDataQuality
        });
      }

      //guidelines
      let daddGuide = datas?.recommendation.additional_guidelines;

      if (datas?.detail?.additional_guidelines.count_guidelines >= 9) {
        brokenresult = {
          type: "Guidelines",
          nama: "Additional Guidelines",
          status: "Low",
          ket:
            "Sistem sudah memenuhi kriteria standar dalam e-government system.",
          recommendation: daddGuide
        };
      } else if (
        datas?.detail?.brokenlink_checker.count_url_error >= 20 &&
        datas?.detail?.brokenlink_checker.count_url_error < 70
      ) {
        brokenresult = {
          type: "Guidelines",
          nama: "Additional Guidelines",
          status: "Medium",
          ket:
            "Sistem cukup kriteria standar dalam e-government system, namun terdapat beberapa kriteria yang perlu dilengkapi.",
          recommendation: daddGuide
        };
      } else {
        brokenresult = {
          type: "Guidelines",
          nama: "Additional Guidelines",
          status: "High",
          ket: "Sistem perlu dilengkapi dengan beberapa kriteria e-government.",
          recommendation: daddGuide
        };
      }
      detailResult.push(brokenresult);

      // simplicity

      let simplicityresult = simplicity;
      if (simplicityresult <= 3) {
        detailResult.push({
          type: "Guidelines",
          nama: "Simplicity",
          status: "High",
          ket: "Sistem Anda membutuhkan perbaikan di sisi UI dan UX.",
          recommendation: arrSimplicity
        });
      } else if (simplicityresult > 4 && simplicityresult < 8) {
        detailResult.push({
          type: "Guidelines",
          nama: "Simplicity",
          status: "Medium",
          ket:
            "Sistem Anda sudah cukup baik. Namun, memerlukan beberapa perbaikan di sisi UI dan UX.",
          recommendation: arrSimplicity
        });
      } else {
        detailResult.push({
          type: "Guidelines",
          nama: "Simplicity",
          status: "Low",
          ket: "Sistem Anda sudah baik",
          recommendation: arrSimplicity
        });
      }

      datas.table = detailResult;

      let newarr = new Array(detailResult.length);

      newarr.map(() => false);

      // setIsTableShow(newarr)

      this.result = datas;
    },

    setBantuActiveTab(name, index) {
      this.activeTab = name;
      this.activeTabIndex = index;
    },
    setActiveTabIndex(index) {
      this.activeTabIndex = index;
    },
    formatDate(date) {
      return Moment(date).format("DD MMM YYYY [|] HH:mm [WIB]");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
