<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <RoleSelect
        :key="userList"
        width="240px"
        :title="null"
        placeholder="Select an User"
        :data="userList"
        :selected="{ id: selectedUser.id, name: selectedUser.name }"
        :onSelected="
          val => {
            selectedUser = val;
            selectedUser.id = val ? val.id : null;
            form.user_id = val.id;
            search = '';
            data = [];
            page = 1;
            loading = true;

            showApiAddBtn(false);
            getData(val.id);
          }
        "
      />

      <div v-if="selectedUser.id" class="d-flex align-items-center">
        <div class="per-page-wrapper mr-4">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div v-if="selectedUser.id" class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.no`]="{ item }">
          {{ data.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.nilai`]="{ item }">
          <v-chip color="#4d8eca" small dark text-color="#FFFFFF">
            {{
              item.broken_link +
                item.data_quality +
                item.guideline +
                item.performance +
                item.responsive +
                item.simplicity +
                item.ssl
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <!-- <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div> -->
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="
                selectedData = item;
                showViewDialog = true;
              "
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="dialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.id ? "Edit URL" : "Tambah URL" }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>

                <b-form-group>
                  <div class="input-title">URL Aplikasi</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.url.$model"
                    :state="validateState('url')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    URL is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit_2"
                  :disabled="formLoading"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showViewDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="showViewDialog" width="90vw">
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  showViewDialog = false;
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <KesehatanSistemResult :data="selectedData" />
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import moment from "moment";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";

import KesehatanSistemResult from "./KesehatanSistemResult.vue";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened",
    "showApiAddBtn"
  ],
  components: {
    RoleSelect,
    KesehatanSistemResult
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no", width: "70px" },
        { text: "URL Aplikasi", value: "url", width: "100%" },
        { text: "Nilai", value: "nilai", width: "150px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      userList: [],
      data: [],
      dataModule: [],
      selectedData: {},
      selectedUser: {
        id: null,
        name: null
      },

      form: {
        url: null
      },

      loading: true,
      formLoading: false,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false,

      errors: []
    };
  },
  validations: {
    form: {
      url: {
        required,
        minLength: minLength(1)
      }
    }
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getUserData() {
      ApiService.get(
        `calculate-programs/?user_id=${
          JSON.parse(JwtService.getUserInfo()).id
        }`,
        "sistemsehat"
      )
        .then(({ data }) => {
          this.userList = data;
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getData(user_id = this?.selectedUser?.id) {
      ApiService.get(`results/?program_id=${user_id}`, "sistemsehat")
        .then(({ data }) => {
          if (data) {
            this.data = data;
            this.showApiAddBtn(true);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      let _this = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit_2"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.formLoading = true;

      let formData = {
        // token: JwtService.getToken("sistemsehat"),
        token: null,
        type: true,
        url:
          _this.form.url.indexOf("://") == -1
            ? "http://" + _this.form.url
            : _this.form.url
      };

      if (!this?.selectedData?.id) {
        // Create
        ApiService.post("kesehatan-sistem", formData, "sistemsehat")
          .then(async data => {
            if (data?.status == 200 && data?.data?.status == "success") {
              _this.closeDialog();

              let _data = data.data;

              await this.$swal({
                title:
                  "Berhasil melakukan penilaian secara otomatis, apakah anda ingin melakukan penilaian lanjutan untuk mengetahui nilai Kualitas Data dan Simplicity?",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Simpan",
                cancelButtonText: "Tidak",
                showLoaderOnConfirm: true,
                preConfirm: function(result) {
                  return new Promise(async function(resolve, reject) {
                    if (result) {
                      await ApiService.post(
                        `results/`,
                        {
                          access_control: 0,
                          broken_link:
                            _data.calculate.brokenlink_checker.result_percent,
                          created: moment()
                            .toDate()
                            .toISOString(),
                          data_quality: 1.2,
                          guideline:
                            _data.calculate.additional_guidelines
                              .result_percent,
                          is_manual: false,
                          performance:
                            _data.calculate.website_performance
                              .number_request_percent +
                            _data.calculate.website_performance
                              .page_load_percent +
                            _data.calculate.website_performance
                              .page_size_percent +
                            _data.calculate.website_performance
                              .respon_time_percent,
                          program_id: _this?.selectedUser?.id,
                          recommendation: JSON.stringify(_data),
                          responsive: _data.calculate.responsive.result_percent,
                          simplicity: 3,
                          ssl: _data.calculate.ssl_certificate.result_percent,
                          url: _this.form.url
                        },
                        "sistemsehat"
                      )
                        .then(() => {
                          _this.getUserData();
                          _this.getData();
                          _this.resetForm();
                          _this.closeDialog();

                          resolve();
                        })
                        .catch(() => {
                          reject();
                        });
                    }
                  });
                },
                allowOutsideClick: () => !this.$swal.isLoading()
              });
            }

            return data;
          })
          .then(data => {
            if (data?.data) {
              this.formLoading = false;

              // submitButton.classList.remove(
              //   "spinner",
              //   "spinner-light",
              //   "spinner-right"
              // );
            }
          });
      }
    },
    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Penilaian Sistem?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`results/${id}/`, "sistemsehat")
                .then(() => {
                  _this.getUserData();
                  _this.getData();

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        url: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
