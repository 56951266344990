var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative z4"},[_c('div',{staticClass:"c-datatable-header d-flex justify-content-between mb-7 pr-16"},[_c('div',{staticClass:"per-page-wrapper"},[_c('div',[_vm._v("Show:")]),_c('div',{staticStyle:{"width":"1px","background":"#D7D4D3"}}),_c('div',[_c('b-dropdown',{attrs:{"size":"sm","variant":"link","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticStyle:{"margin-left":"-15px"}},[_vm._v(_vm._s(_vm.perPage))])]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 10}}},[_vm._v("10")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 20}}},[_vm._v("20")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 50}}},[_vm._v("50")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 100}}},[_vm._v("100")])],1)],1)]),_c('div',[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-2 pl-3 pr-7",attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"c-datatable"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":_vm.perPage,"page":_vm.page,"loading":_vm.loading,"fixed-header":"","hide-default-footer":"","dense":""},on:{"pagination":_vm.onPageChanged},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.active ? "Active" : "Inactive")+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.created_at ? _vm.formatDate(item.created_at) : "-")+" ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"custom-control custom-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.active),expression:"item.active"}],staticClass:"custom-control-input",attrs:{"size":"lg","type":"checkbox","id":'customSwitches_' + item.id},domProps:{"checked":Array.isArray(item.active)?_vm._i(item.active,null)>-1:(item.active)},on:{"click":function($event){return _vm.openConfirmationDialog(item)},"change":function($event){var $$a=item.active,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "active", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "active", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "active", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'customSwitches_' + item.id}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"c-datatable-actions d-flex"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-edit",on:{"click":function($event){_vm.selectedData = item;
              _vm.form = Object.assign({}, _vm.form, item);
              _vm.openDialog();}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/edit-icon.svg")}})]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-delete",on:{"click":function($event){return _vm.deleteData(item.id)}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/delete-icon.svg")}})])])]}}],null,true)}),_c('div',{staticClass:"c-datatable-page d-flex align-items-center justify-content-center mt-8"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"prev-next-btn",on:{"click":function($event){_vm.page > 1 ? (_vm.page -= 1) : undefined}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/chevron-left.png"),"height":"16px"}})]),_c('b-form-input',{staticClass:"h-auto text-center mx-1 py-2",staticStyle:{"height":"40px","width":"50px"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"prev-next-btn",on:{"click":function($event){_vm.page < _vm.pagination.pageCount ? (_vm.page = parseInt(_vm.page) + 1) : null}}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":(_vm.baseAppPath + "/media/chevron-left.png"),"height":"16px"}})])],1)],1),(true)?_c('v-app',{staticClass:"overflow-hidden",style:({ height: _vm.dialog ? 'inherit' : '0px' })},[_c('v-dialog',{attrs:{"width":"400px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"c-dialog"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"relative pb-10 px-20 pt-20"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"dialog-close",on:{"click":function($event){_vm.closeDialog();
                _vm.resetForm();
                _vm.selectedData = {};}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/close-icon.png"),"width":"14px","height":"14px"}})]),_c('div',{staticClass:"dialog-title"},[_vm._v(" "+_vm._s(_vm.selectedData.name ? "Edit Pengguna" : "Tambah Pengguna")+" ")]),_c('b-form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0),_c('b-form-group',{attrs:{"label":""}},[_c('div',{staticClass:"input-title"},[_vm._v("Nama Pengguna")]),_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-2 pl-3 pr-7",attrs:{"state":_vm.validateState('name'),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(" Name is required. ")])],1),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],ref:"kt_submit",staticClass:"btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3",staticStyle:{"border-radius":"100px"}},[_vm._v(" Submit ")])],1)],1)],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }